.co-nav {
  display: flex;
 // padding: 1rem 4rem;
 align-items: center;
  background-color: var(--color-primary);

  width: 100%;
  height: var(--navbar-height);
  top: 0;
  z-index: 99;

  &__brand--text {
    color: var(--color-white);
    text-decoration: none;
  }
}

.bar-container{
  display: flex;
  padding: 5px ;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
}
.bg-image{
  max-height: 200px;
  width: 100%;
  object-fit: cover;
}
.user-avatar {

  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.co-nav__brand--img {
  max-height: 50px;
  height: 100%;
}

.text-logo-middle{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 17px;
}
.ask-help-container{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1 1 auto;
}
@media (max-width: 450px) {
  .text-nav-middle {
      display: none;
  }
}