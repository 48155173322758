span.cls_002 {
  font-family: Arial, serif;
  font-size: 12.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: italic;
  text-decoration: none;
}

div.cls_002 {
  font-family: Arial, serif;
  font-size: 12.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: italic;
  text-decoration: none;
}

span.cls_003 {
  font-family: Arial, serif;
  font-size: 22px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}

div.cls_003 {
  font-family: Arial, serif;
  font-size: 18.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}

span.cls_004 {
  font-family: Arial, serif;
  font-size: 24px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}

div.cls_004 {
  font-family: Arial, serif;
  font-size: 10.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}

span.cls_005 {
  font-family: Arial, serif;
  font-size: 10.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: italic;
  text-decoration: none;
}

div.cls_005 {
  font-family: Arial, serif;
  font-size: 10.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: italic;
  text-decoration: none;
}

.centered-pdf {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.cf-navbar__links--link {
  display: flex;
  align-items: center;
}

.cert-container {
  position: relative;
  visibility: hidden;
  height: 0;
  width: 0;
  overflow: hidden;
}

.bg-certificate {
  width: 1200px;
  height: 794px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  &-playsafely {
    background-image: url("../../assets/cert.png");
  }
  // &-al {
  //   background-image: url("../../assets/cert.png");
  // }

  // &-noal {
  //   background-image: url("../../assets/cert.png");
  // }
}
.bg-certificate-coachsafely {
  width: 792px;
  height: 612px;
  background-size: contain;
  background-position: center;
  background-image: url("../../assets/al-cert.jpg");
  

  // &-noal {
  //   background-image: url("../../assets/noal-cert.jpg");
  // }
}
