
.treeInput {
    outline: none;
    border: 0;
    color: black;
    background: var(--grey10);
}

.treeInput:focus {
    outline: none !important;
}

.treeview {
    display: flex;
    align-items: center;
}

.treeview:hover {
    background-color: rgba(232, 162, 28, 0.3);
}


.tree-node--checkbox {
    width: 15px !important;
    height: 15px;
}