.main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.map-container {
  flex: 1 1 auto;
}
.app {
  width: 100%;
  height: 100%;
}

.map-tooltip-container {
  color: #30404d;
  width: 100%;
  height: 100%;
}
.map-tooltip-title {
  width: 100%;
  font-weight: bolder;
  text-align: center;
}

.map-tooltip-item-container {
  width: 100%;
  display: flex;
  align-self: center;
}

.map-tooltip-item-label {
  flex: 1 1 auto;
}

.map-tooltip-item-value {
  font-weight: bolder;
  flex: 0 0 auto;
}
.tooltip{
  
  position: absolute;
  padding: 4px;
  padding-top: 4px;
  padding-right: 10px;
  padding-bottom:10px;
  padding-left: 4px;
  background: rgba(0, 0, 0, 0.8);
  background-image: initial;
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  max-width: 300px;
  font-size: 12px;
  line-height: 16px;
  z-index: 9;
  pointer-events: none;
}