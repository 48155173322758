.notification_container {
  background-color: #EDEB98;
  padding: 20px 0;
  margin-bottom: 10px;


  &--btn {
    border: none;
    background: transparent;
    font-weight: bold;
    font-size: 18px;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.module-content {
  display: flex;
  align-items: center;
  padding: 10px;
  font-weight: bold;
  justify-content: space-between;
}

.course-summary-content {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.module-title {
  margin-left: 5px;
  font-size: 20px;
  font-weight: bold;
  color: var(--primary);
}

.course-status {
  margin-right: 5px;
  font-size: 17px;
  color: grey;
}

.course-completion-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
}


.module-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.module-description {
  margin-left: 5px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 17px;
  color: grey;
}

.cf-navbar__links--link {
  border: none;

  &:hover {
    cursor: pointer;
  }
}

.print-button {
  display: flex;
  align-items: center;
  color: var(--primary)
}


.module-content-title {
  max-width: 300px;
  width: 100%;
  text-align: left;
}

.module-content-title:hover {
  color: var(--primary)
}

.results-container {
  min-width: 80%;
}


.course-completion-container .cf-navbar__links--link-primary {
  margin: 0 auto;
}

.user-certificate-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  flex-wrap: wrap;
}


.cert-history {
  min-width: 80%;
  padding: 10px;
  padding-bottom: 20px;

  @media screen and (max-width: 576px) {
    width: 100%;
  }
}

.print-user-cert {
  padding: 10px 15px !important;
  font-size: 12px !important;
  background: transparent !important;
  color: var(--primary)!important;

  &:hover {
    text-decoration: underline;
  }
}
