.quiz-answer {
  display: flex;
  margin: 5px;
}
.quiz-radiobox {
  flex: 0 0 20px;
  height: 15px;
  margin-top: 4.5px;
  margin-right: 5px;
  accent-color: var(--primary);
}
.quiz-question {
  margin: 30px;
}
.quiz-title {
  font-size: 20px;
  font-weight: bold;
  padding: 0 10px;
}
.quiz-question-title {
  font-size: 17px;
  font-weight: bold;
}
.quiz-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.grade-percentage {
  margin: 0 auto;
  margin-top: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__shape {
    max-width: 200px;
  }
}

.quiz-container {
  overflow-y: auto;
  height: 100%;
}

.quiz-btns {
  display: flex;
  align-items: center;
}
.pass-quiz-btn {
  margin-left: 40px;
}


