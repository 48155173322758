.overlay {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99999999;
  height: 100%;
  width: 100%;
  background-color: rbga(0,0,0,0.4);
}

.model {
  max-width: 700px;
  background: #fff;
  position: absolute;
  z-index: 999999999;
  margin:0 auto;
  left: 0;
  right: 0;
  top: 20px;
  text-align: left;
  border: 1px solid silver;

  &__header, &__body {
    padding: 15px 20px;
  }

  &__header {
    font-size: 24px;
    font-width: bold;
    border-bottom: 1px solid silver;
    display: flex;
  }


  &__footer {
    padding: 15px 20px;
    border-top: 1px solid silver;
    align-items: center;
    justify-content: flex-end;
    display: flex;
  }
}