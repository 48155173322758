@import "./_bootstrap-grid.scss";

.app-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.formContainerMain {
  margin: 20px auto;
  width: calc(100% - 4rem);
}

.formContainer {
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px;
  border: solid 0.5px rgb(202, 201, 201);
  border-left: solid 5px grey;
  padding: 1em;
}

.formContainer:hover {
  border-left: solid 5px chocolate;
}

.formItem {
  margin: 1em;
}

.horizontal-container {
  display: flex;
  flex-direction: row;
}

.centered-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.full-container {
  width: 100%;
  height: 100%;
}

.full-growth {
  flex: 1 1 auto;
}

.button-hover {
  color: silver;
}

.button-hover:hover {
  color: var(--selection);
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

@import "./_style.scss";

.footer-section {
  padding: 5rem 0;
}

* {
  font-family: inherit;

  &:focus {
    outline: none;
  }
}

.mapContainer {
  width: 100%;
  height: 100vh;
}

.parent-form-input {
  width: 100%;
  max-width: 350px;
}

.parent-form-input-short {
  width: 100%;
  max-width: 100px;
}

@media screen and (max-width: 768px) {
  .parent-form-input,
  .parent-form-input-short {
    max-width: 100%;
    flex-basis: 100%;
  }
  .print-certificate-button {
    margin: 0 auto;
  }

  form {
    padding-bottom: 50px;
  }
}

.flex-nowrap {
  flex-wrap: nowrap;
}
