.course-cmp {
  width: 100%;
  height: 100%;
  display: flex;

  @media screen and (max-width: 992px) {
    display: block;
  }

  .course-content {
    flex: 1 1 auto;
  }

  .course-tree {
    flex: 0 0 300px;
    padding: 10px;
    border-left: 1px solid silver;
  }

  &__body {
    min-height: 60vh;
  }

  &-btns {
    display: flex;
    justify-content: flex-end;

    &-next, &-back {
      padding: 5px 10px;

      &:hover {
        cursor: pointer;
      }
    }

    &-next {
      background-color: var(--primary);
      color: var(--color-white);
    }

    &-back {
      background-color: var(--color-black);
      color: var(--color-white);
    }
  }

}

.course-content, .course-tree {
  height: 100%
}

.course-cmp {
  height: calc(100vh - 100px);
}

.h-100 {
  height: 100%;
}

.course-summary-content {
  padding-bottom: 5rem;
}

.course-tree-mobile {
  @media screen and (max-width: 992px) {
    position: fixed;
    overflow-y: auto;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: #fff;
  }
  @media screen and (max-width: 576px) {

    .course-outline-text-container {
      font-size: 12px;
    }
  }
}

.course-content-icon {
  text-align: right;
  padding-right: 10px;

  div {
    padding: 5px;
  }
}