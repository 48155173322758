.login-container-box{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap:10px
}
.login-sub-container{
    margin:0px;
    max-width:450px ;
    width:450px;
    padding: 10px;
    margin: 0px 10px;

}
.login-message{
    margin-top: 90px;
    height: 540px;
}