.video-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.selected-word {
  color: #fff;
  position: relative;
  background-color: var(--color-primary);

  //span {
  //    position: relative;
  //    z-index: 3;
  //    background-color: #f04d2a;
  //}
  //
  //&:before {
  //    content: '';
  //    height: calc(100% + 2px);
  //    width: calc(100% + 2px);
  //    position: absolute;
  //    background-color: #f04d2a;
  //    z-index: 2;
  //    left: -3px
  //}
}

.transcript {
  padding: 30px 10px;

  @media screen and (max-width: 768px) {
    padding: 10px 10px;
  }
  scroll-behavior: smooth;

  &__body {
    max-width: 85%;
    margin: 0 auto;
    max-height: 250px;
    overflow-y: auto;
    position: relative;
    @media screen and (max-width: 768px) {
      max-width: 95%;
      max-height: 230px;
    }
  }
}
