.payment-container {
  margin: 5vh auto 0;
  max-width: 500px;
  padding: 0 10px;

  form {
    .cf-navbar__links--link {
      padding: 9px 25px;

      span {
        font-size: 24px;
        margin-left: 10px;
      }
    }
    .redeem-btn {
      padding: 10px 15px;

      &:disabled {
        opacity: 0.4;
      }
    }
  }

  .payment-logos {
    max-width: 100%;
    max-height: 100px;
  }

  .enrollment-btns {
    align-items: flex-end;

    a {
      color: black;
      text-decoration: none;

      &:hover {
        border-bottom: 1px solid grey;
      }
    }
  }
}

.coupon-input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}
.coupon-input {
  margin: 0;
}

.coupon-input:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.coupon-input:disabled {
  background-color: rgb(210, 210, 210);
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.coupon-text {
  padding: 5px 10px;
  font-size: 13px;
  display: flex;
  align-items: flex-start;

  &-icon {
    color: #5cb85c;
    margin-right: 5px;
    margin-top: 2px;
  }
}

.amount {
  font-size: 30px;
  font-weight: bold;
  margin: 0 0 30px;
  padding: 0 10px;
}
