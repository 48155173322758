.cv-page-container {
  padding-top: 8rem;

  .cv-component {
    position: relative;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15);
    border-radius: 0.5rem;
    padding: 1.5rem 3rem;
    margin-bottom: 1.5rem;
  }

  .profile-pic-container {
    .mantine-Image-root {
      .mantine-Image-placeholder, .mantine-Image-image {
        border: 5px solid var(--color-primary);
      }
    }
  }

  .cv__profile-pic {
    margin-top: -8rem;
    margin-bottom: 1rem;
  }
}

