

.outline-page-container {
    display: flex;
    width: 100%;
    align-items: center;
}

.outline-page-text-container {
    font-size: 16px;
    font-weight: bold;
    flex: 1 1 auto;
    text-align: left;
    margin-left: 3px;
}

.course-outline-container {
    display: flex;
    width: 100%;
    align-content: center;
}

.course-outline-selected {
   color: var(--primary);
}

.course-info-container {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.course-outline-text-container {
    margin-left: 5px;
    display: flex;
    font-weight: 100;
}

.course-outline-text-container-small {
    font-size: 10px;
}

.course-menu-button-container {
    display: flex;
    align-items: center;
}

.course-menu-action-container {
    padding-right: 5px;
}

.outline-container {
    padding: 5px;
    overflow-y: auto;
}
