.userDiv-Main-Container {
    display: flex;
    align-items: center;
    margin: 0px 10px;
    color: silver;
    font-size: 12px;
}

.userDiv-Main-accountImg {
    width: 45px;
    height: 45px;
    margin: 4px;
    border-radius: 25px;
    border: solid 1px silver;
}
