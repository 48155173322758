.auth-container {
  padding: 4rem 5rem;
  background: #F8F8F8;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 2rem;
  margin-top: 10rem;
  box-shadow: var(--shadow-primary);

  @media screen and (max-width: 768px) {
    padding: 2.5rem 3rem;
  }

  &--icon {
    color: var(--color-primary);
    font-size: 15rem;
  }

  &--link {
    color: var(--color-grey);
    text-decoration: underline;
    cursor: pointer;

    &-darker {
      color: var(--color-grey-dark);
    }
  }
  
}

.signup-page {
  padding: 10rem 0;
}