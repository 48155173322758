.account {
  max-width: 95vw;
  margin: 0 auto;
  padding: 5rem 7rem;

  @media screen and (max-width: 768px) {
    padding: 1rem 2rem;
  }

  background-color: #f1f1f1;
  border-radius: 4px;

  input:focus {
    outline: none;
  }

  .section-title {
    text-align: left;
    color: var(--primary);
    font-size: 3rem;
    font-weight: bold;
    margin-top: 2rem;
    white-space: nowrap;
    margin: 3rem 0 2rem;

    @media screen and (max-width: 768px) {
      font-size: 2rem;
    }
  }


  .date-picker-container input,
  .ch-input,
  select {
    width: 100%;
    font-size: 16px;
    color: #666;
    padding: 0.462em 0.769em;
    font-family: inherit;
    font-weight: inherit;
    font-size: 1em;
    line-height: 1.65;
    max-width: 100%;
    border: 1px solid #e1e1e1;
    background: #fff;
    border-radius: 3px;
  }

  select {
    height: 40px;
  }

  .ch-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  input[type='submit'] {
    font-size: 1em;
    width: auto;
    margin: 0 16px 0 0;
    font-weight: 700;
    letter-spacing: 1px;
    border: none;
    padding: 7px 15px;
  }

  .react-tel-input .form-control {
    font-family: "Raleway", "Helvetica Neue", Arial, sans-serif;
    font-size: 1em;
    color: #666;
  }

  label {
    display: inline-block;
    font-weight: 400;
    margin: 10px 0px 0 0;
    line-height: 1.3;
    font-size: 14px;
  }

  select {
    padding: 2px;
  }

  &__body {
    min-height: 60vh;
  }

  &-btns {
    display: flex;
    justify-content: flex-end;
    margin-top: 5rem;

    &-next,
    &-back {
      padding: 5px 10px;

      &:hover {
        cursor: pointer;
      }
    }

    &-next {
      background-color: var(--primary);
      color: var(--color-white);
      max-width: 10rem;
    }

    &-back {
      background-color: var(--color-black);
      color: var(--color-white);
    }


  }

  .section-end {
    padding: 3rem;
    background-color: var(--primary);
    text-align: center;
    border-radius: 3rem;
    width: 200px;
    margin: 0 auto;
    color: var(--color-white);
    cursor: pointer;
  }

  .check-box-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;

    label {
      margin: 0;
      font-size: 1em;
      margin-left: 5px;
    }

    input {
      min-width: 20px;
      min-height: 20px;
    }


    &-text {
      input[type='text'] {
        width: auto;
        height: auto;
      }
    }



  }

  .print-button {
    margin: 2rem auto 0;
  }

  .react-datepicker-popper {
    transform: translate3d(63.5px, 56px, 0px) scale(1.3) !important;
  }


}


.date-picker-container {
  position: relative;


  .date-picker--icon {
    position: absolute;
    right: 10px;
    top: 15px;
    font-size: 13px;
  }
}

.error-input {
  border: 1px solid red !important;
}

.ag-theme-balham .ag-ltr .ag-cell {
  border-right: 1px solid #aaa;
}



.grid-container {

  @media screen and (max-width: 768px) {
    padding: 15px 10px;
  }

  &__grid {
    height: calc(100vh - 350px);
    width: calc(100vw - 200px);
  }

  .quick-filter {
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    //align-items: end;
    //justify-content: space-between;
    &--input {
      padding: 5px 10px;
      min-width: 25%;
    }

    &--results {
      opacity: 0.7;
      font-size: 14px;
      margin-left: 2rem;
    }

  }
}

.loader {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #f04d2a;
  /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;

  &-center {
    margin: 50px auto;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.cell-expired {
  color: red !important;
}