.course {
  max-width: 80vw;
  margin: 0 auto;

  .section-title {
    text-align: center;
    color: var(--primary);
    font-size: 5rem;
    font-weight: bold;
    margin-bottom: 2rem;
    white-space: nowrap;
  }

  input,select {
    width: 100%;
  }
  &__body {
    min-height: 60vh;
  }
  &-btns {
    display: flex;
    justify-content: flex-end;

    &-next,&-back {
      padding: 5px 10px;
      &:hover {
        cursor: pointer;
      }
    }
    &-next {
      background-color: var(--primary);
      color: var(--color-white);
    }
    &-back {
      background-color: var(--color-black);
      color: var(--color-white);
    }
  }

}