.login-container {
  min-height: calc(100vh - 131px);
  height:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  color: white;
  padding: 10rem 0;
  @media screen and (max-width: 768px) {
    padding: 6rem 0;
  }
  background-image: url("../assets/hero-1.jpeg");
  background-repeat: no-repeat;
  background-size:cover;
  background-position:center;

  .login-bg {
    padding: 4rem 5rem;
    background: #F8F8F8;
    align-items: center;
    display: flex;
    flex-direction: column;
    border-radius: 2rem;

    @media screen and (max-width: 768px) {
      padding: 2.5rem 3rem;
    }
  }
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.login-input-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 5px 0;
  width: 30rem;
  color: var(--color-black);
}

.login-form-labels {
  flex: 1 0 auto;
  text-align: left;
  margin: 5px;
  color: var(--color-black);
}

.login-input-text {
  border-radius: 2.5rem;
  background-color: rgba(230, 230, 230, 0.8);
  border: none;
  padding: 1rem 2rem;
  color: var(--color-black);
  min-width: 100%;
  font-size: 2rem;
}

.login-input-text:focus {
  outline: none;
  background-color:  rgba(230, 230, 230, 0.6);

}

.login-input-container {
  position: relative;

}

.login-button {
  font-size: 1.6rem;
  width: 100%;
  height: 5rem;
  border-radius: 2.5rem;
  background:none;
  &:focus {
    outline: none;
    border-radius: 2.5rem;
    box-shadow: none;
    background-color: rgba(250, 245, 250, 1);
  }
  &:hover {
    cursor: pointer;
  }
  &-primary, &:hover  {
    background-color: var(--primary);
    color: var(--color-white);
    border: none;
  }

}



.login-input-icon {
  position: absolute;
  top: 1.5rem;
  left: 1rem;
}

.login-link {
  font-size: 1.2rem;
  margin: 1rem 0.5rem;
  cursor: pointer;
  color: #999999;
  text-decoration: none;
  display: inline;
}

.login-link:hover {
  color: #5D6063;
}


.ch-mt-1 {
  margin-top: 1rem;
}

.ch-mt-2 {
  margin-top: 1.5rem;
}

.login-container--icon {
  color:var(--primary);
  font-size: 15rem;
}

.secondary-nav {
  padding: 1rem 3rem;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  //border-bottom: solid 0.5px grey;
}

.marks-bg {
  // background-image: url("../assets/play-marks.png");
  // background-repeat: no-repeat;
  // background-position:top;
  // background-size: contain;
}

.error-message {
  margin-left: 6px;
  font-size: 14px;
  margin-top: 3px;
  color: #D8000C;
}

.cf-navbar__links--link-primary {
  background-color: var(--primary);
  color: var(--color-white)!important;
  font-weight: 700;
  padding: 10px;
  border-radius: 5px;
  
}
