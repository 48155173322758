.u-flex-center {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-center {
  text-align: center;
}

.u-w-100 {
  width: 100%;
}

.u-w-100-p-0 {
  width: 100%;
  padding: 0px;
}
.u-color-primary {
  color: var(--color-primary);
}

.u-py-xl {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.u-ml-sm {
  margin-left: 0.5rem;
}
.u-ml-md {
  margin-left: 1rem;
}

.u-mt-md {
  margin-top: 1rem;
}

.u-mt-xl {
  margin-top: 4rem;
}

.u-mb-md {
  margin-bottom: 1rem;
}
.u-capitalize-label {
  label {
    text-transform: capitalize;
  }
}

.u-radius-50 img {
  border-radius: 50%;
}

.u-position-relative {
  position: relative;
}

.u-cursor-pointer {
  cursor: pointer;
}

.u-display-inline {
  display: inline;
}
.u-color-primary {
  color: var(--color-primary);
}

.u-error-red {
  border: 1px solid #f03e3e;
  border-radius: 5px;
}
