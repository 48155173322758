.module-title {
  margin-left: 5px;
  font-size: 20px;
  font-weight: bold;
  color: var(--primary);
}

.module-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  gap: 10px;
}

.module-description {
  margin-left: 5px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 17px;
  color: grey;
}