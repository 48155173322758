
.course-survey {
  padding: 20px 0;
  .question {
    font-weight: 400;
    margin: 20px 0 10px;
    line-height: 1.3;
    font-size: 14px;
  }

  textarea {
    width: 100%;
    font-size: 16px;
    color: #666;
    padding: 0.462em 0.769em;
    font-family: inherit;
    font-weight: inherit;
    font-size: 1em;
    line-height: 1.65;
    max-width: 100%;
    border: 1px solid #e1e1e1;
    background: #fff;
    border-radius: 3px;
  }

  .error-text {
    border-color: #d9534f
  }

  .error-text-radio {
    outline: 1px auto  #d9534f;
  }
}
