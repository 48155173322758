.dropdown {
    position: relative;
}

.dropdown__menu {
    min-width: 220px;
    position: absolute;
    top: 45px;
    width: 100%;
    right: 0;
    color: #2c2c2c;
    background-color: white;
    border: solid 1px grey;
    border: solid 1px gray;
    color: var(--darkgrey);
    z-index: 1000;
}

.dropdown__menu--item {
    padding: 10px;
    font-size: 14px;
    opacity: 0.8;
    cursor: pointer;
    border-bottom: solid 1px grey;
    display: flex;
    align-items: center;
}

.dropdown__menu--item:hover {
    border-bottom: solid 1px silver;
    background-color: var(--primary);
    color: white;
}


.dropdown__menu--item .icon {
    margin-right: 10px;
}


.cursor-pointer {
    cursor: pointer;
}