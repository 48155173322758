.sc-icon {
  color: var(--color-primary);
  cursor: pointer;
  position: relative;

  &:after {
    content: '';
    background-color: rgba(0, 0, 0, 0.08);
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: none;
  }

  &:hover:after {
    display: block;
  }


  &-sm {
    font-size: 1.3rem;

    &:after {
      width: 2.6rem;
      height: 2.6rem;
    }
  }

  &-md {
    font-size: 2.2rem;

    &:after {
      width: 4.4rem;
      height: 4.4rem;
    }
  }


}